<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" class="relative z-10" @close="$emit('close')">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black/25" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div
          class="flex min-h-full items-center justify-center p-4 text-center"
        >
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="w-full max-w-md overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all"
            >
              <DialogTitle
                as="h3"
                class="text-lg font-medium leading-6 text-gray-900"
              >
                エラー
              </DialogTitle>
              <div v-if="apiResponse" class="mt-2">
                <p class="text-sm text-gray-500">
                  {{ message }}
                </p>
                <p class="text-sm text-gray-500">
                  お手数ですが、担当販売者へご連絡頂ますようお願い申し上げます。
                </p>
                <p
                  v-if="apiResponse.sold_out.sales_hub.phone_number"
                  class="text-sm text-gray-500"
                >
                  {{ apiResponse.sold_out.sales_hub.name }}
                  {{ apiResponse.sold_out.sales_hub.phone_number }}
                </p>
              </div>

              <div class="mt-4">
                <button
                  type="button"
                  class="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                  @click="$emit('close')"
                >
                  閉じる
                </button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue';

import type { SoldOutSchema } from '@/schemas/sold_out_schema';

interface Props {
  isOpen: boolean;
  apiResponse: SoldOutSchema | null;
}

const props = withDefaults(defineProps<Props>(), {
  isOpen: false,
  apiResponse: null,
});

interface Emits {
  (e: 'close'): void;
}
defineEmits<Emits>();

const message = computed(() => {
  if (!props.apiResponse) return '';
  const { detail, sold_out: soldOut } = props.apiResponse;
  const { dishes } = soldOut;
  const dishNames = dishes.map((dish) => dish.name);
  return `${dishNames.join('、')}の${detail}`;
});
</script>
