<script setup lang="ts">
import { AdvancedImage } from '@cloudinary/vue';
import {
  ChevronLeftIcon,
  ShoppingCartIcon,
  XMarkIcon,
} from '@heroicons/vue/24/outline';
import { FetchError } from 'ofetch';
import { z } from 'zod';

import { discountSchema } from '@/schemas/discount_schema';

import type { RouteLocationRaw } from '#vue-router';

import type { PositionInfoSchema } from '@/schemas/position_info_schema';
import type { PriceVerifyResponseSchema } from '@/schemas/price_verify_response_schema';
import type { PurchaseLogSchema } from '@/schemas/purchase_log_schema';
import type { SoldOutSchema } from '@/schemas/sold_out_schema';

const getDiscountByCodeResponseSchema = discountSchema
  .merge(
    z.object({
      is_available: z.literal(false),
      message: z.string(),
    }),
  )
  .or(
    discountSchema.merge(
      z.object({
        is_available: z.literal(true),
      }),
    ),
  );

type GetDiscountByCodeResponse = z.infer<
  typeof getDiscountByCodeResponseSchema
>;

definePageMeta({
  auth: true,
});

const auth = useAuth();
const config = useRuntimeConfig();
const route = useRoute();
const { cld, plugins: cloudinaryPlugins } = useCloudinary();

const step = ref(0);
const isSelectMemuModalOpen = ref(false);
const isCompleteModalOpen = ref(false);
const purchasedLog = ref<PurchaseLogSchema | null>(null);

const showCouponForm = ref(false);

const locationParams = computed(() => {
  return {
    location_id: route.query.l?.toString() ?? null,
    position_name: route.query.p?.toString() ?? null,
  };
});

const formData = ref({
  location_id: locationParams.value.location_id,
  position_name: locationParams.value.position_name,
  menu_id: '',
  quantity: 1,
  payment_method: '',
});

const controller = ref(new AbortController());
const priceLoading = ref(false);
const price = ref({
  total: 0,
  discounted_total: null as number | null,
});

// クーポン関連
/**
 * クーポンコード
 */
const couponCode = ref('');
/**
 * クーポンコード入力欄
 */
const couponCodeField = ref<HTMLInputElement | null>(null);
/**
 * クーポンコード確認中かどうか
 */
const inquringCoupon = ref(false);
/**
 * クーポンリスト
 */
// TODO: 価格と同じく一定のイベントで更新するようにする
const coupons = ref<GetDiscountByCodeResponse[]>([]);

/**
 * クーポンごとのチェックアウトエンドポイントレスポンスのキャッシュ
 */
const applyCouponData = ref<{
  [key: string]: {
    message?: string;
  };
}>({});

/**
 * 売り切れエラー
 */
const soldOutError = ref<{
  isOpen: boolean;
  apiResponse: SoldOutSchema | null;
}>({
  isOpen: false,
  apiResponse: null,
});

if (!locationParams.value.location_id || !locationParams.value.position_name) {
  await navigateTo({
    name: 'purchase-location',
  });
}

const {
  data: positionData,
  error,
  refresh,
} = await useFetch<PositionInfoSchema>(
  `/locations/${locationParams.value.location_id}/${locationParams.value.position_name}/info/`,
  {
    method: 'GET',
    headers: {
      Authorization: auth.tokenStrategy.token?.get().toString() ?? '',
      'Content-Type': 'application/json',
    },
    baseURL: config.public.BASE_URL,
  },
);

if (error.value) {
  alert(error.value);
  await navigateTo({
    name: 'purchase-location',
  });
}

const toNextStep = async () => {
  // エラーをリセット
  soldOutError.value = {
    isOpen: false,
    apiResponse: null,
  };

  const resp = await verifyPrice();
  if (resp.ok) {
    // 問題なければ次のステップへ
    step.value = 1;
  } else if (resp.code === 'sold_out') {
    // 売り切れの場合
    const apiResponse = resp as SoldOutSchema;
    soldOutError.value = {
      isOpen: true,
      apiResponse,
    };
  } else {
    // その他のエラー
    // TODO: エラー処理
    alert('画面を更新して、もう一度お試しください。');
  }
};

const toPrevStep = () => {
  step.value = 0;
};

const getPaymentComponent = () => {
  switch (formData.value.payment_method) {
    case 'cash':
      return resolveComponent('PaymentCash');
    case 'bnpl':
      return resolveComponent('PaymentBNPL');
    case 'credit_card_3d_secure_dgft_veri-trans4g_mdk':
      return resolveComponent('PaymentCredit3dSecureDgftVeriTrans4gMdk');
    case 'paypay_dgft_veri-trans4g_mdk':
      return resolveComponent('PaymentPaypayDgftVeriTrans4gMdk');
    case 'no_charge':
      return resolveComponent('PaymentNoCharge');
    default:
      return resolveComponent('PaymentMethodDisabled');
  }
};

const payment = () => {
  const compose = getPaymentComponent();
  return h(compose, {
    formData: formData.value,
    discountIds: coupons.value
      .filter((coupon) => coupon.is_available)
      .map((coupon) => coupon.id)
      .filter(
        (couponId) => applyCouponData.value[couponId]?.message === undefined,
      ),
    totalPrice: price.value.total,
    paymentAmount: price.value.discounted_total ?? price.value.total,
    onPrev: toPrevStep,
    onComplete: (_purchasedLog: PurchaseLogSchema) => {
      isCompleteModalOpen.value = true;
      step.value = 0;
      purchasedLog.value = _purchasedLog;
    },
    onReject: (e: any) => {
      step.value = 0;
      if (e instanceof FetchError && e.statusCode === 400) {
        try {
          const data = e.response?._data;
          if (data && data?.code === 'sold_out') {
            // 売り切れの場合
            const apiResponse = data as SoldOutSchema;
            soldOutError.value = {
              isOpen: true,
              apiResponse,
            };
            return;
          }
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(error);
        }
      }
      // TODO: ある程度デザイン性のあるアラートにする
      alert(
        '支払いに失敗しました。現金の場合はもう一度お試しいただくか、他の支払い方法をお試しください。',
      );
    },
  });
};

const closeCompleteModal = async (to: RouteLocationRaw) => {
  isCompleteModalOpen.value = false;
  formData.value.menu_id = '';
  formData.value.quantity = 1;
  formData.value.payment_method = '';
  couponCode.value = '';
  coupons.value = [];
  applyCouponData.value = {};
  soldOutError.value = {
    isOpen: false,
    apiResponse: null,
  };
  showCouponForm.value = false;
  await navigateTo(to);
};

const selectedItem = computed(() => {
  if (
    !positionData.value ||
    !positionData.value.prices ||
    !formData.value.menu_id
  ) {
    return null;
  }

  return (
    positionData.value.prices.find(
      (item) => item.menu.id === formData.value.menu_id,
    ) ?? null
  );
});

const salesBase = computed(() => {
  return (
    positionData.value?.location?.sales_hub?.base ||
    positionData.value?.delivery_base
  );
});

const tradelawUrl = computed(() => {
  switch (salesBase.value?.id) {
    case config.public.BASE_ZEN_ID:
      return config.public.BASE_ZEN_COMMERCE_URL;
    case config.public.BASE_UKI_ID:
      return config.public.BASE_UKI_COMMERCE_URL;
    case config.public.BASE_JOY_ID:
      return config.public.BASE_JOY_COMMERCE_URL;
    case config.public.BASE_SEN_ID:
      return config.public.BASE_SEN_COMMERCE_URL;
    default:
      return null;
  }
});

const getPrice = async () => {
  if (controller.value) controller.value.abort();

  if (
    !formData.value.menu_id ||
    Number.isNaN(formData.value.quantity) ||
    formData.value.quantity < 1
  ) {
    priceLoading.value = false;
    price.value = {
      total: 0,
      discounted_total: null,
    };
    return;
  }

  try {
    controller.value = new AbortController();
    priceLoading.value = true;

    const data = await $fetch<{
      ok: boolean;
      total: number;
      discounted_total: number;
      discount_messages: Record<string, string>;
    }>('/calc-price/', {
      method: 'POST',
      headers: {
        Authorization: auth.tokenStrategy.token?.get().toString() ?? '',
        'Content-Type': 'application/json',
      },
      body: {
        ...formData.value,
        discount_ids: coupons.value
          .filter((coupon) => coupon.is_available)
          .map((coupon) => coupon.id),
      },
      baseURL: config.public.BASE_URL,
      signal: controller.value.signal,
    });

    priceLoading.value = false;
    price.value = {
      total: data.total,
      discounted_total: data.discounted_total,
    };
    applyCouponData.value = Object.fromEntries(
      Object.entries(data.discount_messages).map(([key, value]) => [
        key,
        {
          message: value,
        },
      ]),
    );
    if (data.discounted_total === 0) {
      formData.value.payment_method = 'no_charge';
    } else if (formData.value.payment_method === 'no_charge') {
      if (positionData.value) {
        formData.value.payment_method =
          positionData.value.payment_methods?.[0].code ?? '';
      }
    }
  } catch (error) {
    if (error instanceof FetchError && error.name === 'AbortError') {
      // Aborting a fetch throws an error
      // So we can't update state afterwards
    } else {
      throw error;
    }
  }
};

const verifyPrice = async () => {
  const data = await $fetch<PriceVerifyResponseSchema>('/price_verify/', {
    method: 'POST',
    headers: {
      Authorization: auth.tokenStrategy.token?.get().toString() ?? '',
      'Content-Type': 'application/json',
    },
    body: {
      ...formData.value,
      total_price: price.value.total,
      payment_amount: price.value.discounted_total ?? price.value.total,
      discount_ids: coupons.value
        .filter((coupon) => coupon.is_available)
        .map((coupon) => coupon.id)
        .filter(
          (couponId) => applyCouponData.value[couponId]?.message === undefined,
        ),
    },
    baseURL: config.public.BASE_URL,
  });
  return data;
};

const applyCoupon = async () => {
  const code = couponCode.value.trim().toUpperCase();

  if (!code || inquringCoupon.value) {
    return;
  }

  try {
    inquringCoupon.value = true;
    const resp = await $fetch<GetDiscountByCodeResponse>(
      `/discounts/by-code/${code}/`,
      {
        method: 'GET',
        headers: {
          Authorization: auth.tokenStrategy.token?.get().toString() ?? '',
          'Content-Type': 'application/json',
        },
        baseURL: config.public.BASE_URL,
      },
    );
    coupons.value = coupons.value
      .flatMap((coupon) => coupon.id)
      .includes(resp.id)
      ? coupons.value
      : [...coupons.value, resp];
    couponCode.value = '';
  } catch (error) {
    if (error instanceof FetchError && error.statusCode === 429) {
      alert(
        'クーポンコードの確認に失敗しました。しばらく時間をおいてから、もう一度お試しください。',
      );
    } else if (error instanceof FetchError && error.statusCode === 404) {
      alert('クーポンが見つかりませんでした。');
    } else {
      alert('クーポンコードの確認に失敗しました。');
      throw error;
    }
  } finally {
    inquringCoupon.value = false;
  }
};

const removeCoupon = (id: string) => {
  coupons.value = coupons.value.filter((coupon) => coupon.id !== id);
};

onMounted(() => {
  document.addEventListener(
    'visibilitychange',
    () => {
      if (document.visibilityState !== 'hidden') {
        refresh();
        getPrice();
      }
    },
    false,
  );
  if (!formData.value.menu_id && !isSelectMemuModalOpen.value) {
    isSelectMemuModalOpen.value = true;
  }
});

watchEffect(() => {
  // TODO: リクエストの間引き
  getPrice();
});
</script>

<template>
  <div class="space-y-4">
    <page-header
      :icon-component="ShoppingCartIcon"
      page-title="弁当を購入する"
    />

    <location-well
      v-if="positionData"
      class="mx-auto mb-2 max-w-xl"
      :location="positionData"
    />

    <div class="relative">
      <form
        :class="{
          'mx-auto mt-10 max-w-xl space-y-4 rounded-md bg-white px-4 py-6 shadow-md sm:p-6': true,
          reverse: step === 1,
        }"
        data-action="first"
        @submit.prevent="toNextStep"
      >
        <div class="space-y-8 pb-12">
          <div class="grid grid-cols-3 items-start gap-4">
            <button
              type="button"
              class="block cursor-default pt-1.5 text-left text-sm font-medium leading-6 text-gray-900"
              @click="isSelectMemuModalOpen = true"
            >
              商品
            </button>
            <div class="relative col-span-2 mt-0">
              <div
                class="relative w-full bg-white py-1.5 text-left text-gray-900 sm:text-sm sm:leading-6"
              >
                <template v-if="selectedItem">
                  <div class="flex items-center">
                    <AdvancedImage
                      class="size-5 shrink-0"
                      :cld-img="
                        cld.image(selectedItem.menu.image_cloudinary_public_id)
                      "
                      :plugins="cloudinaryPlugins"
                      :alt="selectedItem.menu.name"
                    />
                    <span class="ml-3 grow">
                      {{ selectedItem.menu.name }} ({{
                        selectedItem.price.toLocaleString('ja-JP', {
                          style: 'currency',
                          currency: 'JPY',
                        })
                      }})
                    </span>
                    <span class="ml-2 shrink-0">
                      <button
                        type="button"
                        class="text-sm text-blue-600 underline decoration-blue-600 hover:text-blue-400 hover:decoration-blue-400"
                        @click="isSelectMemuModalOpen = true"
                      >
                        変更
                      </button>
                    </span>
                  </div>
                </template>
                <button
                  v-else
                  type="button"
                  class="text-sm text-blue-600 underline decoration-blue-600 hover:text-blue-400 hover:decoration-blue-400"
                  @click="isSelectMemuModalOpen = true"
                >
                  商品を選択してください
                </button>
              </div>
            </div>
            <Teleport to="body">
              <ClientOnly>
                <LazyPurchaseItemMenuSelectModal
                  :is-open="step === 0 && isSelectMemuModalOpen"
                  :prices="positionData?.prices"
                  @select="
                    (menu) => {
                      formData.menu_id = menu.id;
                      isSelectMemuModalOpen = false;
                    }
                  "
                  @close="
                    () => {
                      isSelectMemuModalOpen = false;
                    }
                  "
                />
              </ClientOnly>
            </Teleport>
          </div>

          <div class="grid grid-cols-3 items-start gap-4">
            <label
              for="quantity"
              class="block pt-1.5 text-sm font-medium leading-6 text-gray-900"
              >個数</label
            >
            <div class="col-span-2 mt-0">
              <div
                class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-yellow-600"
              >
                <input
                  id="quantity"
                  v-model.number="formData.quantity"
                  type="number"
                  name="quantity"
                  class="block flex-1 border-0 bg-transparent py-1.5 pr-1 leading-6 text-gray-900 placeholder:text-gray-400 focus:ring-0"
                  min="1"
                />
                <span
                  class="flex select-none items-center pr-3 text-sm text-gray-500"
                  >個</span
                >
              </div>
            </div>
          </div>

          <div class="grid grid-cols-3 items-start gap-4">
            <label
              for="payment_method"
              class="block pt-1.5 text-sm font-medium leading-6 text-gray-900"
              >決済</label
            >
            <div class="col-span-2 mt-0">
              <select
                v-if="price.discounted_total !== 0"
                id="payment_method"
                v-model="formData.payment_method"
                name="payment_method"
                class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 invalid:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-yellow-600"
                required
              >
                <option value="" disabled>支払い方法を選択してください</option>
                <template v-if="positionData">
                  <template
                    v-for="method in positionData.payment_methods"
                    :key="method.code"
                  >
                    <option
                      v-if="method.code !== 'no_charge'"
                      :value="method.code"
                    >
                      {{ method.name }}
                    </option>
                  </template>
                </template>
              </select>

              <div class="mt-4">
                <Transition name="fade">
                  <button
                    v-if="!showCouponForm"
                    type="button"
                    class="text-sm text-blue-600 underline decoration-blue-600 hover:text-blue-400 hover:decoration-blue-400"
                    @click="
                      showCouponForm = true;
                      $nextTick(() => {
                        couponCodeField?.focus();
                      });
                    "
                  >
                    クーポンを使う
                  </button>
                  <form
                    v-else
                    class="mt-2 flex gap-2"
                    @submit.prevent="applyCoupon"
                  >
                    <input
                      ref="couponCodeField"
                      v-model="couponCode"
                      type="text"
                      class="block w-full rounded-md border-0 py-1.5 uppercase text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-yellow-600"
                      pattern="[A-z0-9\-]*"
                      placeholder="クーポンコードを入力してください"
                      autocomplete="off"
                      required
                    />
                    <button
                      type="submit"
                      class="inline-flex flex-none items-center justify-center gap-x-2 rounded-md bg-gray-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600 disabled:cursor-not-allowed"
                      :disabled="inquringCoupon"
                    >
                      <template v-if="inquringCoupon">
                        <svg
                          class="size-5 animate-spin text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          aria-hidden="true"
                        >
                          <circle
                            class="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            stroke-width="4"
                          ></circle>
                          <path
                            class="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                        <span class="sr-only">確認中...</span>
                      </template>
                      <template v-else>適用</template>
                    </button>
                  </form>
                </Transition>
                <Transition name="list">
                  <TransitionGroup
                    v-if="coupons.length > 0"
                    name="list"
                    tag="div"
                    class="mt-4 space-y-2"
                  >
                    <div
                      v-for="coupon in coupons"
                      :key="coupon.id"
                      class="flex items-start gap-x-1"
                    >
                      <CheckoutCoupon
                        class="relative grow overflow-x-hidden"
                        :discount="coupon"
                        :message="
                          !coupon.is_available
                            ? coupon.message
                            : applyCouponData[coupon.id]?.message
                        "
                      />
                      <div class="flex-none">
                        <button
                          type="button"
                          @click="() => removeCoupon(coupon.id)"
                        >
                          <XMarkIcon class="size-5 text-red-500" />
                        </button>
                      </div>
                    </div>
                  </TransitionGroup>
                </Transition>
              </div>
            </div>
          </div>

          <TotalPrice
            :loading="priceLoading"
            :price="price.total"
            :fixed-price="price.discounted_total"
          />
        </div>

        <div class="mx-auto max-w-xl">
          <button
            type="submit"
            class="inline-flex w-full items-center justify-center gap-x-2 rounded-md bg-red-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
          >
            <ShoppingCartIcon class="-ml-0.5 size-5" />
            購入手続きへ
          </button>
        </div>

        <div v-if="positionData" class="mx-auto max-w-xl pt-4">
          <p v-if="salesBase" class="text-sm">
            当商品は{{ salesBase.name }}が販売します
          </p>
          <a
            v-if="tradelawUrl"
            :href="tradelawUrl"
            class="text-sm text-blue-600 underline decoration-blue-600 hover:text-blue-400 hover:decoration-blue-400"
            target="_blank"
            rel="noopener noreferrer"
          >
            特定商取引法に基づく表記
          </a>
        </div>
      </form>

      <div
        :class="{
          'absolute inset-x-0 top-0 mx-auto max-w-xl space-y-8 rounded-md bg-white px-4 py-6 shadow-md sm:p-6': true,
          reverse: step === 1,
        }"
        data-action="second"
      >
        <div class="mx-auto max-w-xl">
          <button
            type="button"
            class="flex items-center text-sm font-semibold leading-5 text-gray-600 hover:text-gray-700 hover:underline hover:decoration-gray-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
            @click="toPrevStep"
          >
            <ChevronLeftIcon class="size-5" />
            戻る
          </button>
        </div>

        <component :is="payment()" class="mx-auto max-w-xl space-y-6" />
      </div>
    </div>

    <Teleport to="body">
      <ClientOnly>
        <PurchaseCompleteModal
          v-if="purchasedLog"
          :is-open="isCompleteModalOpen"
          :purchase-log="purchasedLog"
          @close="closeCompleteModal"
        />
      </ClientOnly>

      <ClientOnly>
        <SoldOutErrorModal
          :is-open="soldOutError.isOpen"
          :api-response="soldOutError.apiResponse"
          @close="soldOutError.isOpen = false"
        />
      </ClientOnly>
    </Teleport>
  </div>
</template>

<style scoped lang="postcss">
[data-action='first'] {
  transform: rotateY(0deg);
  transition: transform 300ms 150ms;

  &.reverse {
    transform: rotateY(90deg);
    transition: transform 300ms;
  }
}

[data-action='second'] {
  transform: rotateY(90deg);
  transition: transform 300ms;

  &.reverse {
    transform: rotateY(0deg);
    transition: transform 300ms 150ms;
  }
}

/**
  slide-fade transition
*/
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

/**
  fade transition
*/
.fade-enter-active,
.fade-leave-active {
  will-change: opacity;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-leave-active {
  position: absolute;
}

/**
  list transition
*/
.list-move,
.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

.list-leave-active {
  position: absolute;
}
</style>
