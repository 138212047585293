import { Cloudinary } from '@cloudinary/url-gen';

import { lazyload, responsive, placeholder } from '@cloudinary/vue';

export const useCloudinary = () => {
  // TODO: 設定で切り替えられるようにする
  return {
    cld: new Cloudinary({
      cloud: {
        cloudName: 'okiben',
      },
    }),
    plugins: [lazyload(), responsive({ steps: 200 }), placeholder()],
  };
};
