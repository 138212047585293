import type { NuxtApp } from '#app';
import type { RuntimeConfig } from 'nuxt/schema';

export class AuthorizeController {
  constructor(
    private readonly auth: NuxtApp['$auth'],
    private readonly config: RuntimeConfig,
    private readonly locationId: string,
    private readonly positionName: string,
    private readonly paymentMethodCode: string,
  ) {}

  async postToken(
    token: string,
    body: { [key: string]: string | number | string[] } = {},
  ) {
    return await $fetch<{
      next_url: string;
    }>(
      `/locations/${this.locationId}/${this.positionName}/dgft_token_server_connection_credential/${this.paymentMethodCode}/`,
      {
        method: 'POST',
        headers: {
          Authorization: this.auth.tokenStrategy.token?.get().toString() ?? '',
          'Content-Type': 'application/json',
        },
        baseURL: this.config.public.BASE_URL,
        body: {
          ...body,
          token,
        },
      },
    );
  }

  async postCardId(
    cardId: string,
    body: { [key: string]: string | number | string[] } = {},
  ) {
    return await $fetch<{
      next_url: string;
    }>(
      `/locations/${this.locationId}/${this.positionName}/dgft_token_server_connection_credential/${this.paymentMethodCode}/`,
      {
        method: 'POST',
        headers: {
          Authorization: this.auth.tokenStrategy.token?.get().toString() ?? '',
          'Content-Type': 'application/json',
        },
        baseURL: this.config.public.BASE_URL,
        body: {
          ...body,
          card_id: cardId,
        },
      },
    );
  }
}
