import { z } from 'zod';

export const discountSchema = z.object({
  id: z.string().uuid(),
  title: z.string().min(1),
  discount_code: z.string(),
  fixed_amount_discount_value: z.number().int().positive().optional(), // fixed_amount_discount_value or percentage_discount_value
  percentage_discount_value: z.number().int().positive().optional(), // fixed_amount_discount_value or percentage_discount_value
  max_uses: z.number().int().positive().optional(),
  max_uses_per_customer: z.number().int().positive().optional(),
  starts_at: z.string().datetime({ offset: true }),
  ends_at: z.string().datetime({ offset: true }),
});

export type Discount = z.infer<typeof discountSchema>;
