<script setup lang="ts">
import { CurrencyYenIcon } from '@heroicons/vue/24/outline';

import type { PurchaseLogSchema } from '~/schemas/purchase_log_schema';

interface Emits {
  (event: 'complete', purchaseLog: PurchaseLogSchema): void;
  (event: 'reject', e: any): void;
  (event: 'prev'): void;
}
const emits = defineEmits<Emits>();

interface Props {
  formData: {
    location_id: string;
    position_name: string;
    menu_id: string;
    quantity: number;
    payment_method: string;
  };
  discountIds: string[];
  totalPrice: number;
  paymentAmount: number;
}

const props = withDefaults(defineProps<Props>(), {});

const auth = useAuth();
const config = useRuntimeConfig();

const submitting = ref(false);
const isPaid = ref(false);

const disabled = computed(() => submitting.value || isPaid.value);

const submit = async () => {
  if (submitting.value) return;

  try {
    submitting.value = true;
    const data = await $fetch<PurchaseLogSchema>('/purchased/', {
      method: 'POST',
      headers: {
        Authorization: auth.tokenStrategy.token?.get().toString() ?? '',
        'Content-Type': 'application/json',
      },
      body: {
        ...props.formData,
        discount_ids: props.discountIds,
        total_price: props.totalPrice,
        payment_amount: props.paymentAmount,
      },
      baseURL: config.public.BASE_URL,
    });
    isPaid.value = true;
    emits('complete', data);
  } catch (e: any) {
    emits('reject', e);
  } finally {
    submitting.value = false;
  }
};
</script>

<template>
  <div>
    <div class="text-center font-medium leading-6 tracking-wider text-gray-900">
      <p>クーポンを使用し、料金が無料になります。</p>
      <p>追加の支払いは必要ありません。</p>
      <p>問題がなければ、次へ進んでください。</p>
    </div>

    <button
      type="button"
      class="inline-flex w-full items-center justify-center gap-x-2 rounded-md bg-red-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 disabled:cursor-not-allowed disabled:opacity-50"
      :disabled
      @click="submit"
    >
      <svg
        v-if="submitting"
        class="-ml-1 mr-3 size-5 animate-spin text-white"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        aria-hidden="true"
      >
        <circle
          class="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          stroke-width="4"
        ></circle>
        <path
          class="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
      <CurrencyYenIcon class="-ml-0.5 size-5" />
      <span v-if="isPaid">そのままお待ちください</span>
      <span v-else>次へ進む（無料）</span>
    </button>
  </div>
</template>
