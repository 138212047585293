<template>
  <div class="flex flex-wrap gap-x-2 gap-y-1 text-xs font-medium text-gray-500">
    <div v-if="discount.ends_at" class="inline-flex items-center">
      <ClockIcon class="mr-1 inline-block size-4" aria-hidden="true" />
      <span>{{ datetimeFormat(discount.ends_at) }}まで</span>
    </div>
    <OkibenTooltip
      v-if="discount.max_uses_per_customer"
      class="inline-flex items-center"
    >
      <ArrowPathRoundedSquareIcon
        class="mr-1 inline-block size-4"
        aria-hidden="true"
      />
      <span>{{ discount.max_uses_per_customer }}回まで</span>

      <template #tooltip>
        <p class="text-xs">
          このクーポンは1人{{
            discount.max_uses_per_customer
          }}回まで使用できます。
        </p>
      </template>
    </OkibenTooltip>
    <div v-if="discount.max_uses" class="inline-flex items-center">
      <ExclamationTriangleIcon
        class="mr-1 inline-block size-4"
        aria-hidden="true"
      />
      <span>適用回数制限あり</span>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  ArrowPathRoundedSquareIcon,
  ExclamationTriangleIcon,
  ClockIcon,
} from '@heroicons/vue/24/outline';
import dayjs from 'dayjs';

import type { Discount } from '~/schemas/discount_schema';

dayjs.locale('ja-jp'); // eslint-disable-line import/no-named-as-default-member

interface Props {
  discount: Discount;
}

withDefaults(defineProps<Props>(), {});

// methods
const datetimeFormat = (date: string) => {
  return dayjs(date).format('MM/DD HH:mm');
};
</script>
