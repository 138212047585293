<script setup lang="ts">
// @ts-ignore
import Cleave from 'vue-cleave-component';
import {
  RadioGroup,
  RadioGroupLabel,
  RadioGroupDescription,
  RadioGroupOption,
} from '@headlessui/vue';
import { CreditCardIcon } from '@heroicons/vue/24/outline';
import { ZodError } from 'zod';

import { TokenController } from '~/controllers/dgft/vt4g_mdk/credit_card_3d_secure/token';
import { AuthorizeController } from '~/controllers/dgft/vt4g_mdk/credit_card_3d_secure/authorize';
import { CardInfoController } from '~/controllers/dgft/vt4g_mdk/credit_card_3d_secure/card_info';

import type { PurchaseLogSchema } from '~/schemas/purchase_log_schema';

interface Emits {
  (event: 'complete', purchaseLog: PurchaseLogSchema): void;
  (event: 'reject', e: any): void;
  (event: 'prev'): void;
}
const emits = defineEmits<Emits>();

interface Props {
  formData: {
    location_id: string;
    position_name: string;
    menu_id: string;
    quantity: number;
    payment_method: string;
  };
  discountIds: string[];
  totalPrice: number;
  paymentAmount: number;
}
const props = withDefaults(defineProps<Props>(), {});

const auth = useAuth();
const config = useRuntimeConfig();

const submitting = ref(false);
const isRequested = ref(false);

const disabled = computed(() => submitting.value || isRequested.value);

const tokenController = new TokenController(
  auth,
  config,
  props.formData.location_id,
  props.formData.position_name,
  props.formData.payment_method,
);
await tokenController.init();

const cardInfoController = new CardInfoController(
  auth,
  config,
  props.formData.payment_method,
  {
    locationId: props.formData.location_id,
    positionName: props.formData.position_name,
  },
);
const cardList = await cardInfoController.getCardList().catch((e: any) => {
  if (e instanceof ZodError) {
    return [];
  }
  // eslint-disable-next-line no-console
  console.error(e);
  emits('reject', e);
});

const creditCardInfo = ref({
  card_number: '',
  card_expire: '',
  card_cvc: '',
  card_holder_name: '',
});
const cardId = ref<string>('');
const mode = ref<'new' | 'select'>(
  cardList && cardList.length > 0 ? 'select' : 'new',
);

if (cardList && cardList.length > 0) {
  cardList.filter((c) => c.default).forEach((c) => (cardId.value = c.id));
  if (!cardId.value) {
    cardId.value = cardList[0].id;
  }
}

// methods
const getToken = async () => {
  if (submitting.value) return;

  try {
    submitting.value = true;
    const token = await tokenController.getToken(creditCardInfo.value);
    if (token.status === 'success' && token?.token) {
      const authorize = new AuthorizeController(
        auth,
        config,
        props.formData.location_id,
        props.formData.position_name,
        props.formData.payment_method,
      );
      try {
        const result = await authorize.postToken(token.token, {
          ...props.formData,
          discount_ids: props.discountIds,
          total_price: props.totalPrice,
          payment_amount: props.paymentAmount,
        });
        isRequested.value = true;
        location.href = result.next_url;
      } catch (e: any) {
        // eslint-disable-next-line no-console
        console.error(e);
        emits('reject', e);
      }
    }
  } catch (e: any) {
    // eslint-disable-next-line no-console
    console.error(e);
    emits('reject', e);
  } finally {
    submitting.value = false;
  }
};

const postCardId = async () => {
  if (submitting.value) return;

  if (!cardId.value) {
    alert('カードを選択してください');
    return;
  }

  const authorize = new AuthorizeController(
    auth,
    config,
    props.formData.location_id,
    props.formData.position_name,
    props.formData.payment_method,
  );
  try {
    submitting.value = true;
    const result = await authorize.postCardId(cardId.value, {
      ...props.formData,
      discount_ids: props.discountIds,
      total_price: props.totalPrice,
      payment_amount: props.paymentAmount,
    });
    isRequested.value = true;
    location.href = result.next_url;
  } catch (e: any) {
    // eslint-disable-next-line no-console
    console.error(e);
    emits('reject', e);
  } finally {
    submitting.value = false;
  }
};

const submit = async () => {
  await auth.refreshTokens();
  auth.tokenStrategy.token?.sync();

  switch (mode.value) {
    case 'select':
      await postCardId();
      break;
    case 'new':
      await getToken();
      break;
  }
};
</script>

<template>
  <div>
    <div class="text-center font-medium leading-6 tracking-wider text-gray-900">
      クレジットカード決済
    </div>

    <RadioGroup v-model="mode" class="space-y-8">
      <RadioGroupLabel class="sr-only">決済に使用するカード</RadioGroupLabel>

      <RadioGroupOption
        v-if="cardList && cardList.length > 0"
        v-slot="{ checked }"
        value="select"
      >
        <div
          :class="[checked ? 'bg-yellow-300/20 text-white' : 'bg-white']"
          class="relative flex cursor-pointer gap-2 rounded-lg px-5 py-4 shadow-md focus:outline-none"
        >
          <div v-show="checked" class="shrink-0 text-white">
            <svg class="size-6" viewBox="0 0 24 24" fill="none">
              <circle cx="12" cy="12" r="12" fill="#000" fill-opacity="0.2" />
              <path
                d="M7 13l3 3 7-7"
                stroke="#fff"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <fieldset class="grow">
            <RadioGroupLabel
              as="legend"
              class="block text-sm font-medium leading-6 text-gray-900"
            >
              決済カードを選択する
            </RadioGroupLabel>
            <RadioGroupDescription v-if="checked" as="div">
              <div class="mt-2 -space-y-px rounded-md bg-white shadow-sm">
                <div>
                  <label for="card-select" class="sr-only">カード</label>
                  <select
                    id="card-select"
                    v-model="cardId"
                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-yellow-600 sm:text-sm sm:leading-6"
                  >
                    <option v-for="c in cardList" :key="c.id" :value="c.id">
                      {{ c.number }}
                      <template v-if="c.expire">({{ c.expire }})</template>
                    </option>
                  </select>
                </div>
              </div>
              <div class="text-right">
                <NuxtLink
                  :to="{
                    name: 'accounts-profile-cards',
                  }"
                  class="text-sm font-medium text-blue-600 hover:text-blue-500"
                >
                  カード情報を管理
                </NuxtLink>
              </div>
            </RadioGroupDescription>
          </fieldset>
        </div>
      </RadioGroupOption>

      <RadioGroupOption v-slot="{ checked }" value="new">
        <div
          :class="[checked ? 'bg-yellow-300/20 text-white' : 'bg-white']"
          class="relative flex cursor-pointer gap-2 rounded-lg px-5 py-4 shadow-md focus:outline-none"
        >
          <div v-show="checked" class="shrink-0 text-white">
            <svg class="size-6" viewBox="0 0 24 24" fill="none">
              <circle cx="12" cy="12" r="12" fill="#000" fill-opacity="0.2" />
              <path
                d="M7 13l3 3 7-7"
                stroke="#fff"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <fieldset class="grow">
            <RadioGroupLabel
              as="legend"
              class="block text-sm font-medium leading-6 text-gray-900"
            >
              新規カードで決済する
            </RadioGroupLabel>
            <RadioGroupDescription v-if="checked" as="div">
              <div class="mt-2 -space-y-px rounded-md bg-white shadow-sm">
                <div>
                  <label for="card-number" class="sr-only">カード番号</label>
                  <Cleave
                    id="card-number"
                    v-model="creditCardInfo.card_number"
                    :options="{
                      creditCard: true,
                      delimiter: '-',
                    }"
                    type="text"
                    class="relative block w-full rounded-none rounded-t-md border-0 bg-transparent py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-yellow-600 sm:leading-6"
                    placeholder="カード番号"
                    autocomplete="cc-number"
                    @keydown.stop=""
                  />
                </div>
                <div class="flex -space-x-px">
                  <div class="w-1/2 min-w-0 flex-1">
                    <label for="card-expiration-date" class="sr-only"
                      >カード有効期限</label
                    >
                    <Cleave
                      id="card-expiration-date"
                      v-model="creditCardInfo.card_expire"
                      :options="{ date: true, datePattern: ['m', 'y'] }"
                      :raw="false"
                      type="text"
                      class="relative block w-full rounded-none border-0 bg-transparent py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-yellow-600 sm:leading-6"
                      placeholder="MM / YY"
                      autocomplete="cc-exp"
                      inputmode="numeric"
                      @keydown.stop=""
                    />
                  </div>
                  <div
                    v-if="
                      !config.public
                        .CREDIT_3D_SECURE_DGFT_VERI_TRANS4G_MDK_CSC_OPTIONAL
                    "
                    class="min-w-0 flex-1"
                  >
                    <label for="card-cvc" class="sr-only"
                      >セキュリティコード</label
                    >
                    <input
                      id="card-cvc"
                      v-model="creditCardInfo.card_cvc"
                      type="text"
                      class="relative block w-full rounded-none border-0 bg-transparent py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-yellow-600 sm:leading-6"
                      placeholder="セキュリティコード"
                      autocomplete="cc-csc"
                      inputmode="numeric"
                      @keydown.stop=""
                    />
                  </div>
                </div>
                <div>
                  <label for="card-holder-name" class="sr-only"
                    >カード名義人</label
                  >
                  <input
                    id="card-holder-name"
                    v-model="creditCardInfo.card_holder_name"
                    type="text"
                    class="relative block w-full rounded-none rounded-b-md border-0 bg-transparent py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-yellow-600 sm:leading-6"
                    placeholder="カード名義人 （TARO YAMADA）"
                    autocomplete="cc-name"
                    @keydown.stop=""
                  />
                </div>
              </div>
            </RadioGroupDescription>
          </fieldset>
        </div>
      </RadioGroupOption>
    </RadioGroup>

    <button
      type="button"
      class="inline-flex w-full items-center justify-center gap-x-2 rounded-md bg-red-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 disabled:cursor-not-allowed disabled:opacity-50"
      :disabled
      @click="submit"
    >
      <svg
        v-if="submitting"
        class="-ml-1 mr-3 size-5 animate-spin text-white"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        aria-hidden="true"
      >
        <circle
          class="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          stroke-width="4"
        ></circle>
        <path
          class="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
      <CreditCardIcon v-else class="-ml-0.5 size-5" />
      <span v-if="isRequested">そのままお待ちください</span>
      <span v-else>支払う</span>
    </button>
  </div>
</template>
