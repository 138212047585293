<template>
  <button
    ref="reference"
    type="button"
    v-bind="$attrs"
    :aria-describedby="`okiben-tooltip-${uid}`"
    @click.stop="toggleOpen = !toggleOpen"
    @mouseover="mouseover = true"
    @mouseleave="onMouseLeave"
  >
    <slot />
  </button>
  <div
    v-if="open"
    :id="`okiben-tooltip-${uid}`"
    ref="floating"
    class="py-2"
    role="tooltip"
    :style="floatingStyles"
    @mouseleave="onMouseLeave"
  >
    <div class="rounded-md bg-gray-600 p-2 text-sm text-gray-100 shadow-lg">
      <slot name="tooltip" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  // arrow,
  autoUpdate,
  flip,
  offset,
  shift,
  useFloating,
} from '@floating-ui/vue';
import { onClickOutside } from '@vueuse/core';

const instance = getCurrentInstance();

const uid = ref(instance?.uid);

const toggleOpen = ref(false);
const mouseover = ref(false);

const reference = ref<HTMLElement | null>(null);
const floating = ref<HTMLElement | null>(null);

const open = computed(() => toggleOpen.value || mouseover.value);

onClickOutside(reference, (event) => {
  if (
    !reference.value?.contains(event.target as Node) &&
    !floating.value?.contains(event.target as Node)
  ) {
    toggleOpen.value = false;
    mouseover.value = false;
  }
});

const onMouseLeave = (event: MouseEvent) => {
  if (
    !reference.value?.contains(event.relatedTarget as Node | null) &&
    !floating.value?.contains(event.relatedTarget as Node | null)
  ) {
    mouseover.value = false;
  }
};

const { floatingStyles, isPositioned } = useFloating(reference, floating, {
  placement: 'top',
  // offset 入れると onMouseLeave の制御上、マウスが外れた時に消えてしまう
  middleware: [
    flip(),
    offset(),
    // TODO: デザインに工数がかかるので、一旦コメントアウト
    // arrow({ element: floatingArrow }),
    shift(),
  ],
  open,
  whileElementsMounted: autoUpdate,
});

defineOptions({
  inheritAttrs: false,
});

watch(isPositioned, (isPositioned) => {
  if (isPositioned) {
    floating?.value?.focus();
    floating?.value?.scrollIntoView();
  }
});
</script>
