import type { NuxtApp } from '#app';
import type { RuntimeConfig } from 'nuxt/schema';

import { z } from 'zod';

export const tokenServerCredentialSchema = z.object({
  url: z.string().url().min(1),
  api_key: z.string().min(1),
});

export type TokenServerCredential = z.infer<typeof tokenServerCredentialSchema>;

export const creditCardInfoSchema = z.object({
  card_number: z.string().min(1),
  card_expire: z.string().min(1),
  card_cvc: z.string().optional(),
  card_holder_name: z.string().min(1),
});
export type CreditCardInfo = z.infer<typeof creditCardInfoSchema>;

export const tokenResponseSchema = z.object({
  token: z.string().optional(),
  token_expire_date: z.string().optional(),
  req_card_number: z.string().optional(),
  status: z.string().min(1),
  code: z.string().min(1),
  message: z.string().min(1),
});
export type TokenResponse = z.infer<typeof tokenResponseSchema>;

export class TokenController {
  tokenServerCredential: Record<string, string>;

  constructor(
    private readonly auth: NuxtApp['$auth'],
    private readonly config: RuntimeConfig,
    private readonly locationId: string,
    private readonly positionName: string,
    private readonly paymentMethodCode: string,
  ) {
    this.tokenServerCredential = {};
  }

  async init() {
    this.tokenServerCredential = await this.getTokenServerCredential();
  }

  async getTokenServerCredential() {
    return await $fetch<TokenServerCredential>(
      `/locations/${this.locationId}/${this.positionName}/dgft_token_server_connection_credential/${this.paymentMethodCode}/`,
      {
        method: 'GET',
        headers: {
          Authorization: this.auth.tokenStrategy.token?.get().toString() ?? '',
          'Content-Type': 'application/json',
        },
        baseURL: this.config.public.BASE_URL,
        parseResponse: (txt) =>
          tokenServerCredentialSchema.parse(JSON.parse(txt)),
      },
    );
  }

  async getToken(cardInfo: CreditCardInfo) {
    const card = creditCardInfoSchema.parse(cardInfo);

    const body: Record<string, string> = {
      card_number: card.card_number,
      card_expire: card.card_expire,
      cardholder_name: card.card_holder_name,
      token_api_key: this.tokenServerCredential.api_key,
    };
    if (card.card_cvc) {
      body.security_code = card.card_cvc;
    }

    return await $fetch<TokenResponse>(this.tokenServerCredential.url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body,
      parseResponse: (txt) => tokenResponseSchema.parse(JSON.parse(txt)),
    });
  }
}
